import React, {
    type FC,
    type InputHTMLAttributes,
    type PropsWithChildren,
    createContext,
    useContext
} from 'react';

export type RadioGroupContainerOnChangeHandler =
    React.ChangeEventHandler<HTMLInputElement>;

export type IRadioGroupContainerContext = {
    onChange: RadioGroupContainerOnChangeHandler;
    value: InputHTMLAttributes<HTMLInputElement>['value'];
    hasCtx: boolean;
};

const RadioGroupContainerContext = createContext(
    {} as IRadioGroupContainerContext
);

export const useRadioGroupContainer = () =>
    useContext(RadioGroupContainerContext);

export type RadioGroupContainerProps = {
    labelledBy?: string;
} & PropsWithChildren &
    Pick<IRadioGroupContainerContext, 'onChange' | 'value'>;

export const RadioGroupContainer: FC<RadioGroupContainerProps> = ({
    labelledBy,
    children,
    onChange,
    value
}) => {
    const ctx: IRadioGroupContainerContext = React.useMemo(
        () => ({ onChange, hasCtx: true, value }),
        [onChange, value]
    );

    return (
        <RadioGroupContainerContext.Provider value={ctx}>
            <div
                role="radiogroup"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(labelledBy && {
                    'aria-labelledby': labelledBy
                })}
            >
                {children}
            </div>
        </RadioGroupContainerContext.Provider>
    );
};
