import React, { type FC, useCallback, useId, useMemo, useRef } from 'react';
import cn from 'classnames';
import { type CampaignProductResponse } from 'bb/campaigns/types';
import { MaxWidthContainer } from 'bb/common/Containers';
import { useIsScreenSize, useOnce } from 'bb/common/hooks';
import { Section } from 'bb/common/section';
import { type SectionProps } from 'bb/common/section/Section';
import { type Product } from 'bb/subscription/types';
import { Box } from 'bb/ui';
import { Gap } from 'bb/ui/Gap';
import { RadioGroupItem, RadioGroupContainer } from 'bb/ui/RadioGroup';
import css from './subscription.module.scss';
import { SubscriptionCard } from './SubscriptionCard';
import { sortProducts } from './utils';

export interface SubscriptionProductsProps {
    products: Product[];
    selectedProduct: Product | CampaignProductResponse | null;
    setSelectedProduct: (product: Product) => void;
    showProfilePrice?: boolean;
    hideAmount?: boolean;
    singlePayment?: boolean;
    labelledby?: string;
    withPagePadding?: SectionProps['withPagePadding'];
    isLoading?: boolean;
    discountText?: string;
    /**
     * Places focus on the default input element on mount.
     *
     * @defaultValue `false`
     */
    focusDefaultProductOnMount?: boolean;
}

export const SubscriptionCards: FC<SubscriptionProductsProps> = ({
    products: rawProducts = [],
    selectedProduct,
    setSelectedProduct,
    showProfilePrice = false,
    hideAmount,
    singlePayment,
    labelledby,
    withPagePadding = true,
    isLoading = false,
    discountText,
    focusDefaultProductOnMount = false
}) => {
    const isLgUp = useIsScreenSize('lg');
    const defaultProductRef = useRef<HTMLInputElement | null>(null);

    const radioName = useId();
    const products = useMemo(
        () => sortProducts(rawProducts, Boolean(isLgUp)),
        [rawProducts, isLgUp]
    );

    useOnce(() => {
        if (focusDefaultProductOnMount && defaultProductRef.current) {
            defaultProductRef.current.focus();
        }
    });

    const hasDiscountedProduct = products.find(
        (p) => p.originalPrice !== p.price
    );

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const product = products.find(
                (p) => p.type === (event.target.value as Product['type'])
            );

            if (product) setSelectedProduct(product as Product);
        },
        [setSelectedProduct, products]
    );

    return (
        <Section
            withPagePadding={withPagePadding}
            className={cn(isLoading && css.isLoading)}
        >
            <Box>
                <MaxWidthContainer fluid={false} maxWidth={1050}>
                    <RadioGroupContainer
                        onChange={handleOnChange}
                        labelledBy={labelledby}
                        value={selectedProduct?.type ?? undefined}
                    >
                        {/* Avoid deep nesting and call renderProducts */}
                        <Gap
                            justifyContent="center"
                            className={css.stack}
                            spacing={6}
                        >
                            {products.slice(0, 3).map((product) => {
                                return (
                                    <RadioGroupItem
                                        ref={
                                            product.isDefault
                                                ? defaultProductRef
                                                : null
                                        }
                                        key={product.type}
                                        value={product.type}
                                        name={radioName}
                                        aria-label={product.displayName}
                                        labelProps={{
                                            'data-testid': `data-card-${product.type}`,
                                            className: css.radio
                                        }}
                                        id={product.type}
                                        render={(inputProps) => (
                                            <SubscriptionCard
                                                inputProps={inputProps}
                                                product={product}
                                                showProfilePrice={
                                                    showProfilePrice
                                                }
                                                hideAmount={Boolean(hideAmount)}
                                                singlePayment={singlePayment}
                                                hasDiscountedProduct={Boolean(
                                                    hasDiscountedProduct
                                                )}
                                                discountText={discountText}
                                            />
                                        )}
                                    />
                                );
                            })}
                        </Gap>
                    </RadioGroupContainer>
                </MaxWidthContainer>
            </Box>
        </Section>
    );
};
