/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { DummyInput, type DummyInputProps } from '../../DummyInput';
import { useRadioGroupContainer } from '../RadioGroupContainer';

export type RadioGroupItemProps = DummyInputProps;

export const RadioGroupItem = forwardRef<HTMLInputElement, RadioGroupItemProps>(
    ({ children, value, ...restProps }, ref) => {
        const {
            hasCtx,
            onChange,
            value: radioGroupValue
        } = useRadioGroupContainer();

        if (!hasCtx) {
            throw new Error(
                '<RadioGroupItem>: The component has to be wrapped in a <RadioGroupContainer>'
            );
        }

        /**
         * Since the RadioGroupItem depends on state from the
         * RadioGroupContainer we determine the checked state
         * by comparing the value passed to the item with the
         * value coming from the ctx.
         */
        const checked = value === radioGroupValue;

        return (
            <DummyInput
                {...restProps}
                checked={checked}
                value={value}
                onChange={onChange}
                ref={ref}
            >
                {children}
            </DummyInput>
        );
    }
);
