// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { subscriptionStore } from './subscriptionStore';
import { type SubscriptionStore } from './types';

export const StoreContext = React.createContext<SubscriptionStore | null>(null);

export const SubscriptionStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(subscriptionStore);
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export const useSubscriptionStore = (): SubscriptionStore => {
    const store = useContext<SubscriptionStore>(StoreContext);

    if (!store) {
        throw new Error(
            'useSubscriptionStore must be used within StoreProvider'
        );
    }
    return store;
};
