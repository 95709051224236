// @ts-strict-ignore
import { type CampaignResponse, type Campaign } from 'bb/campaigns/types';
import {
    type SubscriptionProductsResponse,
    type SubscriptionProducts,
    type Product
} from 'bb/subscription/types';
import { isNotEqualNum } from 'bb/utils';

const sortByPrice = (a: Product, b: Product): number =>
    a.originalPrice - b.originalPrice;

export const formatSubscriptionProducts = (
    subscriptionProducts: SubscriptionProductsResponse
): SubscriptionProducts => {
    const { products, trialDays, trialHours } = subscriptionProducts;
    let compareProfilePrice: number;
    let compareNrOfProfiles: number;
    let compareHours: number;
    let profilePriceDifference = false;
    let profileMaxDifference = false;
    let hoursLimitDifference = false;
    let lowestPrice = 99999;
    let defaultProduct = null;
    let hasProfiles = false;
    let priceOfProfiles = null;

    const formattedProducts = products.map((product) => {
        const {
            profilePrice,
            hourLimit,
            profileMaxCount,
            price,
            originalPrice,
            type,
            baseType,
            displayName,
            isDefault
        } = product;

        if (isNotEqualNum(compareProfilePrice, profilePrice)) {
            profilePriceDifference = true;
        }
        if (isNotEqualNum(compareNrOfProfiles, profileMaxCount)) {
            profileMaxDifference = true;
        }
        if (isNotEqualNum(compareHours, hourLimit)) {
            hoursLimitDifference = true;
        }
        if (price < lowestPrice) {
            lowestPrice = price;
        }
        if (isDefault) {
            defaultProduct = product;
        }
        if (profilePrice) {
            hasProfiles = true;
            if (priceOfProfiles > profilePrice || !priceOfProfiles) {
                priceOfProfiles = profilePrice;
            }
        }

        compareProfilePrice = profilePrice;
        compareNrOfProfiles = profileMaxCount;
        compareHours = hourLimit;

        return {
            price,
            originalPrice,
            type,
            baseType: baseType ?? null,
            displayName,
            profilePrice,
            hourLimit,
            profileMaxCount,
            isDefault
        };
    });

    return {
        products: formattedProducts.sort(sortByPrice),
        trialDays,
        profilePriceDifference,
        profileMaxDifference,
        hoursLimitDifference,
        lowestPrice,
        defaultProduct,
        hasProfiles,
        profilePrice: priceOfProfiles,
        trialHours
    };
};

/**
 * If the campaign.code is empty we can be sure it's the default state
 * since there's no campaign with an empty string as code.
 */
export const isSubscriptionCampaignDefaultState = (
    campaign?: Campaign | CampaignResponse
) => campaign?.code === '';
