import { type Product } from 'bb/subscription/types';

/**
 * Sorts products according to requirements.
 *
 * We use JS to order instead of CSS in order to not mess with tabIndex.
 * If we only use order, it only reflects the visual order and not the
 * tab order which can be really confusing if dealing with interactive
 * elements.
 *
 * Unfortunately this has a drawback. Cards might shuffle on the first
 * render which isn't really ideal. But at least we end up with the
 * right order while maintaining SSR and a11y.
 */
export const sortProducts = (passedProducts: Product[], isLgUp: boolean) => {
    const products = [...passedProducts];
    const cheapToExpensive = products.sort(
        (a, b) => a.originalPrice - b.originalPrice
    );

    if (isLgUp) {
        /**
         * If we are on desktop we want the following order:
         * Left to right - small to big
         */
        return cheapToExpensive;
    }

    /**
     * If we are on mobile we want the following order:
     * - Default
     * - Most expensive
     * - Less expensive
     */
    return cheapToExpensive
        .reverse()
        .sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
};
