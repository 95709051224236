import { type FunctionComponent, useEffect } from 'react';
import { useApi } from 'bb/api/browser/useApi';
import { formatSubscriptionProducts } from './subscriptionUtils';
import { useSubscriptionStore } from './useSubscriptionStore';

export const FetchSubscriptionProducts: FunctionComponent = () => {
    const store = useSubscriptionStore();

    const { data, error, isLoading } = useApi('/api/subscription/products', {
        enabled: !store.subscriptionProducts?.products?.length
    });

    useEffect(() => {
        if (data) {
            const formattedProducts = formatSubscriptionProducts(data);
            store.setSubscriptionProducts(formattedProducts);
            store.setSubscriptionProductsIsLoading(false);
        } else if (error) {
            store.setSubscriptionProductsError(error.data.message);
            store.setSubscriptionProductsIsLoading(false);
        } else if (isLoading) {
            store.setSubscriptionProductsIsLoading(true);
        }
    }, [error, data, isLoading, store]);

    return null;
};
