// @ts-strict-ignore
import { Debug } from 'bb/app/debug';
import { getHydratedState } from 'bb/app/stores';
import { type Campaign } from 'bb/campaigns/types';
import { type SubscriptionStore, type SubscriptionProducts } from './types';

const storeName = 'subscriptionStore';

const debug = Debug(storeName);

const hydratedState = getHydratedState(storeName);

const initialState = {
    subscriptionProducts: {
        products: [],
        trialDays: 0,
        trialHours: 0,
        profilePriceDifference: false,
        profileMaxDifference: false,
        hoursLimitDifference: true,
        lowestPrice: null,
        defaultProduct: null,
        hasProfiles: false,
        profilePrice: 0
    },
    campaign: {
        campaignProducts: [],
        campaignPriceRules: undefined,
        hasExpired: false,
        code: '',
        ignoreActivation: false,
        trialDays: 0,
        eligibilityRules: []
    },
    nrOfSubscriptionProducts: 0,
    subscriptionProductsIsLoading: true,
    subscriptionProductsError: '',
    ...hydratedState
};

export function subscriptionStore(): SubscriptionStore {
    return {
        ...initialState,

        setSubscriptionProducts(subscriptionProducts: SubscriptionProducts) {
            debug.info('setSubscriptionProducts');
            this.subscriptionProducts = subscriptionProducts;
            this.nrOfSubscriptionProducts =
                subscriptionProducts?.products?.length;
        },

        setSubscriptionProductsError(error: string) {
            debug.info('setSubscriptionProductsError');
            this.subscriptionProductsError = error;
        },

        setSubscriptionProductsIsLoading(isLoading: boolean) {
            debug.info('setSubscriptionProductsIsLoading');
            this.subscriptionProductsIsLoading = isLoading;
        },
        setCampaign(campaign: Campaign) {
            debug.info('setCampaign');
            this.campaign = {
                ...campaign,
                priceRule: campaign?.priceRules?.length
                    ? campaign.priceRules[0]
                    : null
            };
        },
        clearCampaign() {
            debug.info('clearCampaign');
            this.campaign = null;
        },
        dehydrate() {
            debug.info('dehydrate');
            return [
                storeName,
                {
                    subscriptionProducts: {
                        ...this.subscriptionProducts
                    },
                    subscriptionProductsIsLoading: false
                }
            ];
        }
    };
}
