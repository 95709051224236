import React from 'react';
import classNames from 'classnames';
import {
    Amount,
    AmountPerMonth,
    Header,
    HoursPerMonth,
    Text,
    useTranslation
} from 'bb/i18n';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { type Product } from 'bb/subscription/types';
import { Box, Display, Flex, type DummyInputInputProps } from 'bb/ui';
import { Gap } from 'bb/ui/Gap';
import { CheckIcon } from 'bb/ui/Icons/svgs';
import { isDef } from 'bb/utils';
import css from './subscription.module.scss';

const formatDiscount = (discount: number) => `- ${Math.round(discount * 100)}%`;

export type SubscriptionCardsProps = {
    product: Product;
    showProfilePrice: boolean;
    hideAmount: boolean;
    singlePayment?: boolean;
    hasDiscountedProduct?: boolean;
    inputProps?: DummyInputInputProps;
    discountText?: string;
};

const TYPE_TO_COLOR_MAP = {
    basic: { outline: 'secondary-green', background: 'tertiary-green' },
    standard: { outline: 'secondary-yellow', background: 'tertiary-yellow' },
    limited: { outline: 'primary-purple', background: 'tertiary-purple' },
    premium: { outline: 'primary-purple', background: 'tertiary-purple' },
    mini: { outline: 'secondary-red', background: 'tertiary-red' },
    default: { outline: 'gray-02', background: 'gray-01' }
} as const;

export const SubscriptionCard = ({
    product,
    showProfilePrice,
    hideAmount,
    singlePayment,
    hasDiscountedProduct,
    inputProps,
    discountText
}: SubscriptionCardsProps) => {
    const { t } = useTranslation('subscription');
    const { profilePrice, hourLimit, displayName } = product;

    const type = product.baseType ?? product.type ?? 'default';

    const styleType = type.split('_').at?.(-1) ?? type;
    const cardColors =
        TYPE_TO_COLOR_MAP[styleType as keyof typeof TYPE_TO_COLOR_MAP] ||
        TYPE_TO_COLOR_MAP.default;

    const isSelected = inputProps?.checked;
    const percentageNumber = product?.originalPrice
        ? 1 - product.price / product.originalPrice
        : 0;

    const showDiscount =
        hasDiscountedProduct && singlePayment
            ? Boolean(percentageNumber)
            : false;

    const showDiscountedAmount = product.price !== product.originalPrice;

    return (
        <div
            className={css.root}
            style={{
                ...makeCSSVariableFromColor(
                    '--checked-background-color',
                    cardColors.background
                ),
                ...makeCSSVariableFromColor(
                    '--checked-outline',
                    cardColors.outline
                )
            }}
        >
            <Box
                className={css.box}
                style={makeCSSVariableFromColor(
                    '--box-background-color',
                    cardColors.outline
                )}
            >
                <Text
                    data-optimizely={`subscription-card-${type}`}
                    center
                    t={`subscription:${type}Description`}
                />
            </Box>
            <Flex
                direction="column"
                justifyContent="center"
                className={css.content}
            >
                <Display on={showDiscount}>
                    <div className={css.discount}>
                        <Text variant="label" color="primary-white">
                            {formatDiscount(percentageNumber)}
                        </Text>
                    </div>
                </Display>
                <Gap spacing={4}>
                    <Gap spacing={1}>
                        <Header
                            as="h3"
                            variant="h5Secondary"
                            center
                            responsive={false}
                        >
                            {displayName}
                        </Header>

                        <Text center>
                            {hourLimit ? (
                                <HoursPerMonth hours={hourLimit} />
                            ) : (
                                t('subscription:unlimitedListening')
                            )}
                        </Text>
                    </Gap>

                    <div
                        className={css.divider}
                        style={makeCSSVariableFromColor(
                            '--divider-color',
                            'primary-black'
                        )}
                    />
                    <Gap spacing={1}>
                        <Display on={!hideAmount}>
                            <Text center size="large">
                                <AmountPerMonth amount={product?.price} />
                            </Text>
                        </Display>
                        <Display on={Boolean(singlePayment)}>
                            <Text
                                center
                                t="subscription:from"
                                variant="h5Secondary"
                            >
                                <Amount amount={product.price} />
                            </Text>
                        </Display>
                        <Display
                            on={Boolean(
                                showProfilePrice && isDef(profilePrice)
                            )}
                        >
                            <Text
                                center
                                t="subscription:profilePrice"
                                variant="body3"
                            >
                                <AmountPerMonth amount={profilePrice} />
                            </Text>
                        </Display>
                        <Gap spacing={4}>
                            <Text
                                as="span"
                                className={classNames(
                                    !showDiscountedAmount && css.hide
                                )}
                                center
                            >
                                <Text className={css.strikethrough} as="span">
                                    <AmountPerMonth
                                        amount={product.originalPrice}
                                    />
                                </Text>
                                {discountText && '*'}
                            </Text>
                            <Display
                                on={showDiscountedAmount && isDef(discountText)}
                            >
                                <Text center variant="body3">
                                    {discountText}
                                </Text>
                            </Display>
                        </Gap>
                    </Gap>
                </Gap>
            </Flex>

            <div
                className={classNames(css.button, isSelected && css.isSelected)}
                style={makeCSSVariableFromColor(
                    '--button-background-color',
                    cardColors.outline
                )}
            >
                <CheckIcon />
            </div>
        </div>
    );
};
